<template>
  <div id="SKILL_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Skill"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`staff.field.skills`) }}</label>
        <button class="btn-action" @click="skillSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="skills">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="skillBadgeRemove(index)" @badgeClick="skillBadgeClick(item.uuId)"
              :text="`${item.name} (${item.level})`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <SkillSelectorModal v-if="skillSelectorShow" :show.sync="skillSelectorShow" @cancel="skillSelectorCancel" @ok="skillSelectorOk"/>
    <SkillLevelModal :show.sync="skillLevelEditShow" 
      :uuId="skillLevelEdit.uuId" 
      :name="skillLevelEdit.name" 
      :level="skillLevelEdit.level" 
      :cData="skillLevelEdit"
      :edgeName="edgeName"  
      @ok="skillLevelOk"/>
    <template v-for="(item, index) in toConfirmSkills">
      <SkillLevelModal :key="`skill-${index}`" 
        :show.sync="item.show" 
        :uuId="item.uuId" 
        :name="item.name" 
        :level="item.level"
        :cData="item"
        :edgeName="edgeName"  
        @ok="toConfirmSkillOk" 
        @cancel="toConfirmSkillCancel" 
        canApplyAll/>
    </template>
  </div>
</template>

<script>
import { objectClone } from '@/helpers'
import { getCustomFieldInfo } from '@/helpers/custom-fields';
export default {
  name: 'SkillBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    SkillSelectorModal: () => import('@/components/modal/SkillSelectorModal'),
    SkillLevelModal: () => import('@/components/modal/SkillLevelModal')
    
  },
  props: {
    show: { type: Boolean, required: true }
    , skillList: { type: Array, default: () => [] }
    , edgeName: {
      type: String
      , default: 'STAFF-SKILL'
      , validator(value) {
        return value == null || value == 'STAFF-SKILL' || value == 'TASK-SKILL'
      }
    }
  },
  data() {
    return {
      modalShow: false
      , skillSelectorShow: false
      , skills: []
      , toConfirmSkills: []
      , skillLevelEditShow: false
      , skillLevelEdit: {
        uuId:  null
        , name:  null
        , level: null
      }
      , customFields: []
    }
  },
  created() {
    this.modifiedList = []
  },
  beforeMount() {
    this.processWhenShow(this.show);
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
  },
  computed: {
    customFieldKeys() {
      return this.customFields.map(i => i.name);
    }
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.processWhenShow(newValue);
      }
    }
  },
  methods: {
    async processWhenShow(newValue) {
      await getCustomFieldInfo(this, 'SKILL_LINK');
      if (this.modifiedList != null) {
        this.modifiedList.splice(0, this.modifiedList.length)
      }
      this.modalShow = newValue
      if (newValue) {
        this.skills.splice(0, this.skills.length, ...this.skillList)
      }
    },
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , skillSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.skillSelectorShow = false
    }
    , skillBadgeRemove: function(index) {
      this.skills.splice(index,1)
    }
    , skillBadgeClick(id) {
      const selected = this.skills.find(i => i.uuId === id);
      const edit = {
        uuId: selected.uuId
        , name: selected.name
        , level: selected.level
      }
      
      for (const k of this.customFieldKeys) {
        if (Object.hasOwn(selected, k)) {
          edit[k] = selected[k];
        }
      }
      this.skillLevelEdit = edit;
      this.skillLevelEditShow = true;
    }
    , skillSelectorToggle() {
      this.skillSelectorShow = true
    }
    , skillSelectorOk({ details }) {
      const newSkills = details.map(i => { return { uuId: i.uuId, name: i.name, level: null, color: i.color, show: false }});
      this.toConfirmSkills.splice(0, this.toConfirmSkills.length, ...newSkills);
      for(let i = this.toConfirmSkills.length-1; i > -1; i--) {
        this.toConfirmSkills[i].toBeShown = true;
      }
      this.toConfirmSkills[this.toConfirmSkills.length - 1].toBeShown = false;
      this.toConfirmSkills[this.toConfirmSkills.length - 1].show = true;
    }
    , ok() {
      this.$emit('ok', { value: objectClone(this.skills), customFields: this.customFields })
      this.$emit('update:show', false)
    }
    , skillLevelOk({ id, name, level, oldId, customData }) {
      const oldIdIndex = oldId? this.skills.findIndex(i => i.uuId === oldId) : -1;
      if(oldIdIndex != -1) {
        this.skills.splice(oldIdIndex, 1);
      }
      const idIndex = this.skills.findIndex(i => i.uuId === id);
      const selected = this.skills[idIndex];
      selected.uuId = id;
      selected.name = name;
      selected.level = level;
      for (const f of this.customFields) {
        if (Object.hasOwn(customData, f.name)) {
          selected[f.name] = customData[f.name];
        }
      }

    }
    , toConfirmSkillOk({ id, level, applyToAll, customData }) {
      const selected = this.toConfirmSkills[this.toConfirmSkills.findIndex(i => i.uuId === id)]
      selected.level = level;
      for (const f of this.customFields) {
        if (Object.hasOwn(customData, f.name)) {
          selected[f.name] = customData[f.name];
        }
      }
      const toBeShown = this.toConfirmSkills.filter(i => i.toBeShown);
      
      if(toBeShown.length === 0) {
        this.toConfirmSkillCommit();
      } 
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.level = level;
          for (const f of this.customFields) {
            if (Object.hasOwn(customData, f.name)) {
              entry[f.name] = customData[f.name];
            }
          }
        }
        this.toConfirmSkillCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    }
    , toConfirmSkillCancel() {
      this.toConfirmSkillCommit();
    }
    , toConfirmSkillCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmSkills.length; i < len; i ++) {
        this.toConfirmSkills[i].show = false;
      }
      const confirmedSkills = this.toConfirmSkills.filter(i => i.level && i.level.length > 0);
      const ids = confirmedSkills.map(i => i.uuId);
      const currentIds = this.skills.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newSkills = confirmedSkills.filter(i => !currentIds.includes(i.uuId));

      let skill = null;
      for(let i = 0, len = this.skills.length; i < len; i++) {
        skill = this.skills[i];
        if(duplicatedIds.includes(skill.uuId)) {
          const found = confirmedSkills.find(i => i.uuId === skill.uuId);
          skill.level = found.level;
          for (const f of this.customFields) {
            if (Object.hasOwn(found, f.name)) {
              skill[f.name] = found[f.name];
            }
          }
        }
      }

      
      newSkills.forEach(i => {
        const s = {uuId: i.uuId, name: i.name, level: i.level }
        
        for (const f of this.customFields) {
          if (Object.hasOwn(i, f.name)) {
            s[f.name] = i[f.name];
          }
        }
        this.skills.push(s);
      });
    }
  }
}
</script>